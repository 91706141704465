.movies-list-screen {
  padding: 0 30px;
}
@media (max-width: 600px) {
  .movies-list-screen {
    padding: 0px;
  }
}
.movies-list-screen .title-movies {
  color: var(--pink-color);
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 30px;
}

.no-categories {
  width: 100%;
  margin: auto;
  margin-top: 200px;
  text-align: center;
  font-size: 50px;
}
