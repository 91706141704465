.box-categories {
  min-width: 290px;
  height: 220px;
  background-color: var(--white-color);
  color: var(--red-color);
  border-radius: var(--radius);
  box-shadow: 0px 8px 28px 0 #6666fe29;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 800;
  cursor: pointer;
}
.box-categories:hover {
  background-color: rgb(182, 181, 181);
}
