.nav-bar {
  border-bottom: 2px solid var(--red-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  font-weight: 800;
  padding: 35px 0;
  margin-bottom: 50px;
  position: relative;
}
.logo {
  font-size: 24px;
}
.nav-bar ul {
  display: flex;
  flex-direction: row;
}
.nav-bar ul li {
  display: flex;

  justify-content: space-between;
}
.nav-bar li a.active {
  color: var(--red-color);
}
.search {
  padding: 9px;
  border-radius: var(--radius);
  border: 1px solid var(--secondary-black-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  margin-left: 20px;
}
.search input {
  background-color: transparent;
  width: 100%;
  border: none;
  padding: 0 5px;
  color: var(--white-color);
}
@media (max-width: 900px) {
  .search {
    border: 1px solid var(--white-color);
    margin-left: 0px;
  }
}
.search input:focus {
  outline: none;
}
.search svg {
  color: var(--secondary-black-color);
}
.nav-bar .company {
  display: flex;
}
.company a {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.nav-bar .company img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
}

.right-side {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  padding: 0px;
  display: flex;
  z-index: 3;
  top: 0;
  transition: 0.5s;
  background-color: var(--primary-black-color);
  height: 100%;
}
.nav-bar .header-menu {
  display: none;
}
@media (max-width: 900px) {
  .nav-bar {
    padding: 20px;
    justify-content: space-between;
  }
  .right-side {
    padding: 10px;
    display: block;
    position: absolute;
    background-color: var(--secondary-black-color);
    height: 100vh;
  }
  .nav-bar ul {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .nav-bar ul li {
    margin-bottom: 10px;
    padding: 10px 0;
  }
  .nav-bar ul li a {
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid var(--white-color);
  }
  .nav-bar .header-menu {
    display: flex;
    justify-content: space-between;
  }
  .nav-bar .company {
    display: none;
  }
  .nav-bar ul li:first-child {
    padding: 0;
    margin: 0;
    border: none;
  }
  .nav-bar ul li .company {
    display: block;
    margin: 0;
    padding: 0;
  }
  .nav-bar ul li .company a {
    border: none;
  }
}
