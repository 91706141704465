/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: "Avenir";
}

:root {
  --primary-black-color: #1e1e1e;
  --secondary-black-color: #3d3f48;
  --red-color: #b0413d;
  --white-color: #d9d9d9;
  --pink-color: #ff9693;
  --text-white-color: #ffffff;

  --radius: 20px;

  --xs: 0px;
  --sm: 600px;
  --md: 900px;
  --lg: 1200px;
}

body {
  background-color: var(--primary-black-color);
  color: var(--text-white-color);
}

button {
  background-color: var(--red-color);
}
a {
  text-decoration: none;
  color: var(--white-color);
}
.big-font {
  font-size: 50px;
  font-weight: 800;
  margin-bottom: 20px;
}
.md-font {
  font-size: 30px;
  font-weight: 500;
}
a:focus {
  outline: none;
}
img {
  width: 100%;
}
@font-face {
  font-family: Avenir;
  src: url("../public/font/AvenirLTStd-Black.otf");
}

.swiper-pagination-bullet {
  width: 11px !important;
  height: 11px !important;
}
.swiper-pagination-bullet-active {
  background-color: var(--red-color) !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--red-color) !important;
  top: 40%;
}
