.list-box-movies .title-movies {
  color: var(--pink-color);
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 30px;
}

.list-box-movies .list-movie-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
  margin-bottom: 30px;
}
.list-box-movies .list-movie-item .img {
  border-radius: var(--radius);
  width: 228px;
  height: 224px;
  overflow: hidden;
  background-color: var(--white-color);
}
.list-box-movies .list-movie-item .img img {
  width: 100%;
  height: 100%;
}

.list-box-movies .list-movie-item .movie-list-title {
  background-color: var(--secondary-black-color);
  padding: 50px;
  border-radius: var(--radius);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media (max-width: 600px) {
  .list-box-movies {
    width: 360px;
    margin: auto;
  }
  .list-box-movies .list-movie-item {
    flex-direction: column;
    width: 100%;
    padding: 20px;
    gap: 0px;
  }
  .list-box-movies .list-movie-item .img {
    width: 100%;
    height: 300px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .list-box-movies .list-movie-item .movie-list-title {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
}
